import { URL_PREFIX } from 'ev-config/config';

export enum QtcPaths {
  Dashboard = 'dashboard',
  Scheduler = 'schedule',
}

export enum ScheduleStep {
  Accommodation,
  Gender,
  Location,
  Assessment,
}

export enum IntakeFormIds {
  Gender = 'gender',
  Confirm = 'confirm',
  Chaperone = 'chaperone',
}

export const QTC_ROOT = `${URL_PREFIX}/qtc`;
export const QTC_PORTAL = '/api/sch/case_data.html';
export const QTC_PHONE_NUMBER = '(908) 245-9080';
