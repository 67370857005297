import { URL_PREFIX } from 'ev-config/config';

export enum AdminPaths {
  Workflows = 'workflows',
  PagesEBuilder = 'ebuilder',
  PagesForms = 'forms',
  PagesFormEditor = 'form_editor',
  PagesClinical = 'clinical',
  PagesPatient = 'patient',
  PagesClinicalQueue = 'queue',
  PagesClinicalEmbeddedQueue = 'embedded_queue',
  PagesClinicalPatient = 'patient',
  PagesClinicalVisitHistory = 'visit_history',
  PagesClinicalScheduling = 'scheduling',
  PagesPatientNavigationMenu = 'navigation_menu',
  Customers = 'customers',
  Customize = 'customize',
  Roles = 'roles',
  Users = 'physicians',
  Admin = 'admin',
  Settings = 'settings',
  ConnectedDevices = 'connected_devices',
  VideoBackground = 'video_background',
  InterpreterServices = 'interpreter_services',
}

export const ADMIN_ROOT = `${URL_PREFIX}/app_admin`;
