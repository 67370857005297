import { t } from 'i18next';
import { DateTime } from 'luxon';

export type Validator = ({
  requiredErrorMessage,
  value,
  label,
}: {
  value: string;
  label?: string;
  requiredErrorMessage?: string;
  dateFormat?: string;
}) => ValidationResult;

export type ValidationResult = {
  valid: boolean;
  message?: string;
};

export const requiredValidator: Validator = ({
  value,
  label,
  requiredErrorMessage,
}) => {
  return {
    valid: !!value,
    message:
      requiredErrorMessage || t('{{field}} is required', { field: label }),
  };
};

export const emailValidator: Validator = ({ value }) => {
  const result = { valid: false, message: t('Invalid email address') };

  if (typeof value !== 'string' || !value) {
    return result;
  }

  // Copied from evisit-js-utils. Unsure of why we have control characters in the regex.
  const re =
    // eslint-disable-next-line no-control-regex
    /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))){2,6}$/i;

  result.valid = re.test(value);
  return result;
};

export const phoneValidator: Validator = ({ value }) => {
  const result = { valid: false, message: t('Invalid phone number') };
  if (typeof value !== 'string') {
    return result;
  }
  value = value.replace(/\D/g, '');

  if (value.length !== 10 && value.length !== 0) {
    return result;
  }

  return { valid: true };
};

export const dateValidator: Validator = ({
  value,
  dateFormat = 'yyyy-MM-dd',
}) => {
  const parsed = DateTime.fromFormat(value, dateFormat);

  if (parsed.isValid && parsed.year > 999) {
    return { valid: true };
  } else {
    return { valid: false, message: t('Invalid date') };
  }
};

export const dateRangeValidator: Validator = ({ value }) => {
  const [startDate, endDate] = value.split(',');

  const startDateTime = DateTime.fromISO(startDate.trim());
  const endDateTime = DateTime.fromISO(endDate.trim());

  if (endDateTime < startDateTime) {
    return { valid: false, message: t('End date is before the Start date') };
  }

  return { valid: true };
};

export const creditCardExpirationValidator: Validator = ({ value }) => {
  const invalid = { valid: false, message: t('Invalid expiration date') };

  const parts = value.split('/');
  if (parts.length !== 2) {
    return invalid;
  }

  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[1], 10);

  if (isNaN(month) || isNaN(year)) {
    return invalid;
  }

  if (month < 1 || month > 12) {
    return invalid;
  }

  if (year < 0) {
    return invalid;
  }

  return { valid: true, message: '' };
};

export const noopValidator: Validator = () => {
  return { valid: true };
};
