import { Qtc } from 'ev-types';

import api, { Base, Tags } from 'ev-api/api';

import { GetDashboardParams, GetProvidersAndSlotGroupsParams } from './params';
import {
  GetDashboardResponse,
  GetHolidaysResponse,
  GetProvidersAndSlotGroupsResponse,
} from './responses';
import {
  getDashboardTransform,
  getProvidersAndSlotGroupsTransform,
} from './transformers';

export const qtcApi = api.injectEndpoints({
  endpoints: builder => ({
    getDashboard: builder.query<GetDashboardResponse, GetDashboardParams>({
      query: ({ caseId, token }) => ({
        url: `${Base.Sch}/appointments/dashboard`,
        params: { case_id: caseId, sec_token: token || undefined },
      }),
      transformResponse: getDashboardTransform,
      providesTags: [Tags.Qtc],
    }),
    getProvidersAndSlotGroups: builder.query<
      GetProvidersAndSlotGroupsResponse,
      GetProvidersAndSlotGroupsParams
    >({
      query: ({
        gender,
        name,
        state,
        latitude,
        longitude,
        radius,
        startDate,
        endDate,
        appointmentTypeId,
        duration,
      }) => ({
        url: `${Base.Sch}/providers_and_slot_groups`,
        params: {
          gender: gender === Qtc.Gender.NoPreference ? undefined : gender,
          name,
          state_code: state,
          latitude,
          longitude,
          from_datetime: startDate,
          to_datetime: endDate,
          appt_type_id: appointmentTypeId,
          radius,
          total_duration: duration,
        },
      }),
      transformResponse: getProvidersAndSlotGroupsTransform,
    }),
    getHolidays: builder.query<GetHolidaysResponse, void>({
      query: () => ({
        url: `${Base.Sch}/cases/federal_holidays`,
      }),
    }),
  }),
});

export const {
  useGetDashboardQuery,
  useGetProvidersAndSlotGroupsQuery,
  useGetHolidaysQuery,
} = qtcApi;
