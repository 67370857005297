import React from 'react';
import styled, { css } from 'styled-components/macro';

// eslint-disable-next-line no-restricted-imports
import Typography, { TypographyProps } from '@mui/material/Typography';

type SimpleVariant = 'title' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
type Variant = 'description' | 'footnote' | 'caption' | 'body';

type Font = {
  fontFamily: string;
  fontStyle: string;
  fontWeight: number;
};

type FontStyle = {
  fontSize: string;
  lineHeight: string;
};

type TextStyleObjectType = {
  [key: string]: Font & FontStyle;
};

const RegularFont: Font = {
  fontFamily: 'Inter, sans-serif',
  fontStyle: 'normal',
  fontWeight: 400,
};

const SemiBoldFont: Font = {
  ...RegularFont,
  fontWeight: 600,
};

const FontStyles: { [key: string]: FontStyle } = {
  Headline1: {
    fontSize: '28px',
    lineHeight: '48px',
  },
  Headline2: {
    fontSize: '26px',
    lineHeight: '40px',
  },
  Headline3: {
    fontSize: '24px',
    lineHeight: '36px',
  },
  Headline4: {
    fontSize: '22px',
    lineHeight: '32px',
  },
  Headline5: {
    fontSize: '20px',
    lineHeight: '28px',
  },
  Title: {
    fontSize: '18px',
    lineHeight: '24px',
  },
  Body: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  Description: {
    fontSize: '14px',
    lineHeight: '18px',
  },
  Footnote: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  Caption: {
    fontSize: '10px',
    lineHeight: '16px',
  },
};

const createTextStyleObject = (baseFont: Font): TextStyleObjectType => {
  const styles: TextStyleObjectType = {};
  for (const key in FontStyles) {
    styles[key] = {
      ...baseFont,
      ...FontStyles[key],
    };
  }
  return styles;
};

export const TextStyleObject = {
  Regular: createTextStyleObject(RegularFont),
  SemiBold: createTextStyleObject(SemiBoldFont),
};

function getVariant(
  variant: Variant,
  bold?: boolean,
  italic?: boolean,
): TypographyProps['variant'] {
  return `${variant}${italic ? 'Italic' : ''}${bold ? 'Bold' : ''}`;
}

function getSimpleVariant(
  variant: SimpleVariant,
  bold?: boolean,
): TypographyProps['variant'] {
  return `${variant}${bold ? 'Bold' : ''}`;
}

type TextProps = {
  variant: Variant;
  bold?: boolean;
  italic?: boolean;
} & Omit<TypographyProps, 'variant'>;
type SimpleTextProps = {
  variant: SimpleVariant;
  bold?: boolean;
} & Omit<TypographyProps, 'variant'>;

const BaseText = React.forwardRef<HTMLSpanElement, TextProps>(
  ({ variant, bold, italic, children, ...props }, ref) => {
    return (
      <Typography
        variant={getVariant(variant, bold, italic)}
        {...props}
        ref={ref}
      >
        {children}
      </Typography>
    );
  },
);

BaseText.displayName = 'BaseText';

const SimpleBaseText = ({
  variant,
  bold,
  children,
  ...props
}: SimpleTextProps) => {
  return (
    <Typography variant={getSimpleVariant(variant, bold)} {...props}>
      {children}
    </Typography>
  );
};

export const Text = {
  Headline1: styled(SimpleBaseText).attrs({ variant: 'h1' })``,
  Headline2: styled(SimpleBaseText).attrs({ variant: 'h2' })``,
  Headline3: styled(SimpleBaseText).attrs({ variant: 'h3' })``,
  Headline4: styled(SimpleBaseText).attrs({ variant: 'h4' })``,
  Headline5: styled(SimpleBaseText).attrs({ variant: 'h5' })``,
  Title: styled(SimpleBaseText).attrs({ variant: 'title' })``,
  Body: styled(BaseText).attrs({ variant: 'body' })``,
  Description: styled(BaseText).attrs({ variant: 'description' })``,
  Footnote: styled(BaseText).attrs({ variant: 'footnote' })``,
  Caption: styled(BaseText).attrs({ variant: 'caption' })``,
};

export const Styles = {
  Headline1: css`
    font-size: ${FontStyles.Headline1.fontSize};
    line-height: ${FontStyles.Headline1.lineHeight};
    display: block;
  `,
  Headline2: css`
    font-size: ${FontStyles.Headline2.fontSize};
    line-height: ${FontStyles.Headline2.lineHeight};
    display: block;
  `,
  Headline3: css`
    font-size: ${FontStyles.Headline3.fontSize};
    line-height: ${FontStyles.Headline3.lineHeight};
    display: block;
  `,
  Headline4: css`
    font-size: ${FontStyles.Headline4.fontSize};
    line-height: ${FontStyles.Headline4.lineHeight};
    display: block;
  `,
  Headline5: css`
    font-size: ${FontStyles.Headline5.fontSize};
    line-height: ${FontStyles.Headline5.lineHeight};
    display: block;
  `,
  Title: css`
    font-size: ${FontStyles.Title.fontSize};
    line-height: ${FontStyles.Title.lineHeight};
    display: block;
  `,
  Body: css`
    font-size: ${FontStyles.Body.fontSize};
    line-height: ${FontStyles.Body.lineHeight};
  `,
  Description: css`
    font-size: ${FontStyles.Description.fontSize};
    line-height: ${FontStyles.Description.lineHeight};
  `,
  Footnote: css`
    font-size: ${FontStyles.Footnote.fontSize};
    line-height: ${FontStyles.Footnote.lineHeight};
  `,
  Caption: css`
    font-size: ${FontStyles.Caption.fontSize};
    line-height: ${FontStyles.Caption.lineHeight};
  `,
};

const Regular = new Map();
const SemiBold = new Map();

const basicStyle = css`
  font-family: Inter, sans-serif;
  font-style: normal;
`;

for (const [name, style] of Object.entries(Styles)) {
  Regular.set(
    name,
    css`
      ${basicStyle}
      ${style}
      font-weight: 400;
    `,
  );
  SemiBold.set(
    name,
    css`
      ${basicStyle}
      ${style}
      font-weight: 600;
    `,
  );
}

export const TextStyle = {
  Regular: Object.fromEntries(Regular),
  SemiBold: Object.fromEntries(SemiBold),
};

export default Text;
